import React from "react";
import Navigation from "./Navigation";
import {Link} from "react-router-dom";
// import TopLinks from "./TopLinks";
import { withRouter} from "react-router-dom";


class Header extends React.Component {

    render() {

        let phoneNo = this.__extractPhone();
        let cleanPhoneNo = phoneNo.replace(/ /g, "");
        

        console.log('rendering header');
        return (
            <header id="nav_drop_down" data-expand-on-hover="yes" data-expand-on-touch="yes" className="expand-on-touch">
                <div id="nav_drop_down_box">
                    <div className="container">
                        <div className="cvri-sub-container">
                            <div className="row cvri-top">
                                <div className="logo-column">
                                    <div className="lg-wrapper">
                                        <Link to="/" className="logo-link top-tara-logo">
                                            <img src={process.env.PUBLIC_URL + '/images/tara_project_logo.svg'}  alt="Tara Project" />
                                        </Link>
                                        <a href="https://cvridublin.ie/" target="__blank" className="logo-link mpn-cvri-logo-mobile">
                                            <img className="out-cvri-logo" src={process.env.PUBLIC_URL + '/images/cvri_master_logo.png'} alt="Cardiovascular Reasearch Institute Dublin" />
                                        </a>
                                    </div>
                                    
                                    <div className="lg-wrapper">
                                        <a href="https://cvridublin.ie/" target="__blank" className="logo-link mpn-cvri-logo-desktop">
                                            <img className="out-cvri-logo" src={process.env.PUBLIC_URL + '/images/cvri_master_logo.png'} alt="Cardiovascular Reasearch Institute Dublin" />
                                        </a>
                                        <a href="https://www.materprivate.ie/" target="__blank" className="logo-link mpn-main-logo">
                                            <img className="mpn-logo-img" src={process.env.PUBLIC_URL + '/images/logo-network.svg'} alt="Mater Private Network" />
                                        </a>
                                        <a href="https://www.rcsi.com/dublin" target="__blank" className="logo-link mpn-rcsi-logo">
                                            <img className="mpn-logo-img" src={process.env.PUBLIC_URL + '/images/rcsi_logo.png'} alt="University of Medicine and Health Services" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mobile-menu d-block d-sm-none">
                                    <button type="button" id="menu_drop_down" onClick={this.__handleMenuClick}><i className="sr-only">Menu icon</i></button>
                                </div>
                                <Navigation menuItems={this.props.menuItems} data={this.props.data} cleanPhoneNo={cleanPhoneNo} phoneNo={phoneNo} contact={this.props.contact} />
                            </div>
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
            </header>
        )
    }

    componentDidMount() {

        //once we click on a menu item close the mobile menu
        // let list = document.querySelectorAll("nav li");
        // for (var i = 0; i < list.length; ++i) {
        //     list[i].addEventListener("click", (event) => {
        //         this.__closeMenu();
        //     });
        // }

        //search events close menu
        document.getElementById('cvri_search_submit').addEventListener("click", (event) => {
            this.__closeMenu();
        });

        document.querySelector('.activate-search').addEventListener('keypress', (event) => {
            if (event.keyCode === 13) {
                this.__closeMenu();
            }
        });

        this.unlisten = this.props.history.listen((location, action) => {
            this.__closeMenu();
        });
    }

    componentWillUnmount() {
        if ( typeof this.unlisten !== 'function'){
            console.log('this.unlisten() does not exist');
            return;
        }
        this.unlisten();
    }

    __extractPhone = () => {

        const phoneItem = this.props.contact.body.map((item, index) => {
            return (item.type === 'telephone')?item: null;
        }).filter(Boolean);

        let dataPhone = phoneItem[0].value;

        return dataPhone;
    }

    __handleMenuClick = () => {

        const wrapperElement = document.getElementById('wrapper');
        if(wrapperElement.classList.contains('slide-left')) {
            this.__closeMenu();
        } else {
            document.querySelector('#menu_drop_down').classList.add('open');
            document.querySelector('.nav-column').classList.add('open');
            let tara_nav_column = document.querySelector('.tara-nav-column');
            if (tara_nav_column) {
                tara_nav_column.classList.add('open');
            }
            
            let submenus = document.querySelectorAll('li .dropdown-menu');
            for (var i = 0; i < submenus.length; ++i) {
                submenus[i].classList.add('show');
            }
            wrapperElement.classList.add('slide-left');
            setTimeout(function () {
                document.getElementById("wrapper").style.overflow = "inherit";
            }, 500);
        }
    }

    __closeMenu = () => {

        const wrapperElement = document.getElementById('wrapper');
        if(!wrapperElement.classList.contains('slide-left')) {
            return false;
        }
        
        wrapperElement.classList.add('slide-back');
        setTimeout(function () {
            document.querySelector('#menu_drop_down').classList.remove('open');
            document.querySelector('.nav-column').classList.remove('open');
            let tara_nav_column = document.querySelector('.tara-nav-column');
            if (tara_nav_column) {
                tara_nav_column.classList.remove('open');
            }
            document.querySelector('.dropdown').classList.remove('open');
            wrapperElement.classList.remove('slide-left');
            wrapperElement.classList.remove('slide-back');
            wrapperElement.removeAttribute('style');
            let submenus = document.querySelector('li .dropdown-menu');
            for (var i = 0; i < submenus.length; ++i) {
                submenus[i].classList.remove('show');
            }
            document.querySelector('.nav-column').removeAttribute('style');
            if (tara_nav_column) {
                tara_nav_column.removeAttribute('style');
            }
            
        }, 1);
    }

}

export default withRouter(Header);