import React from "react";
import * as constants from '../../constants';

class Title extends React.Component {

    render() {

        const page = this.props.currentPage;
        let content = '';
        if (page && page.hasOwnProperty('featured_image') && page.featured_image && page.featured_image.hasOwnProperty('url')) {
            content = (
                <div className="cvri-hero-wrapper">
                    <span className={"cvri-image " + this.__getPageClassByID(page.id)}>
                        <img src={constants.CMS_URL + page.featured_image.url} alt={this.props.currentPage.title} className="tara-project-page-hero" />
                    </span>
                    <section id="page-title" className="spotlight-landing-layout">
                    
                        <div className="container">
                            <h1>{this.props.currentPage.title}</h1>
                        </div>
                
                    </section>
                </div>
            );
        } else {
            content = (
                <section id="page-title" className="spotlight-landing-layout">
                    <div className="container">
                        <h1>{this.props.currentPage.title}</h1>
                    </div>
                </section>
            );
        }
        return content;
    }



    __getPageClassByID = (ID) => {

        switch (parseInt(ID)) {
            case 1:
                return 'about';
            default:
                return '';
        }
    }

}

export default Title;