import React from "react";
import PageContent from '../page_parts/PageContent';

class NotFoundPage extends React.Component {

    render() {
        let NotFoundHtmlContent = "<h1>Not Found</h1><p>The page you were looking for could not be found...</p>";
        return (
            <>
                <PageContent content={NotFoundHtmlContent} />
            </>
        )
    }

    componentDidMount() {
        document.title = 'Not Found - Tara Project';
    }
}

export default NotFoundPage;