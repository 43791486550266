import React from "react";
import { withRouter} from "react-router-dom";

class SearchBox extends React.Component {

    render() {

        return (
            <div className="search-box">
                <div id="ctl00_SearchBox_pnlSearch" className="searchBox">
                    <label htmlFor="ctl00_SearchBox_txtWord" id="ctl00_SearchBox_lblSearch" className="inv sr-only">Search:</label>
                    <input name="cvri_search" type="text" id="cvri_search" className="form-control activate-search" placeholder="Search" />
                    <input type="submit" name="cvri_search_submit" defaultValue="Go" id="cvri_search_submit" className="btn btn-primary btn-default" />
                </div>
            </div>
        );
    }


    componentDidMount() {

        let searchContainer = document.querySelector('#nav_drop_down .nav-column.search-box-item .search-box');
        let searchContainerInitialColor = searchContainer.style.borderColor;

        document.getElementById('cvri_search_submit').addEventListener("click", (event) => {
            this.__checkSearchBox(searchContainer);
        });

        document.querySelector('.activate-search').addEventListener('keypress', (event) => {
            if (event.keyCode === 13) {
                this.__checkSearchBox(searchContainer, searchContainerInitialColor);
            }
        })
       
    }

    __checkSearchBox = (searchContainer, searchContainerInitialColor = '') => {
    

        let user_search = document.getElementById('cvri_search').value.trim();
           
        if (user_search.trim().length === 0) {
            searchContainer.style.borderColor = "red";
        } else {
            searchContainer.style.borderColor = searchContainerInitialColor;
        }

        //redirect to search results if not there already
        if (window.location.pathname !== '/search/') {
            this.props.history.push(`/search/`)
        }
    }
}

export default withRouter(SearchBox);