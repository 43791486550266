import React from "react";
import {Link} from "react-router-dom";
import {getValidSlug, addEndingSlashToSlug} from '../../utils/general';
import * as constants from "../../constants/index";


class VideoItem extends React.Component {

    render() {

        let currentVideo = this.props.currentVideo;
        let videoImages = currentVideo.images;
        let slug = getValidSlug(currentVideo.slug);
        slug = '/' + constants.EDUCATION_PAGE + '/' + addEndingSlashToSlug(slug);

        return (
            <div className="col-xs-12 col-md-4"> 
                <div className="grid-item-video">
                <Link className="grid-item-image" to={slug} onClick={(e) => this.__moveToTop(e)}>
                    <div className="m_image_container">
                        <img alt={currentVideo.title} src={videoImages[videoImages.length - 1].src} />
                        <i className="play_icon" />
                    </div>
                </Link>
                <div className="grid-item-info">
                    <Link className="header-link" to={slug} onClick={(e) => this.__moveToTop(e)}>
                        <h4 className="item-title">{currentVideo.title}</h4>
                    </Link>
                </div>
                </div>
            </div>
        )
    }

    __moveToTop = (e) => {
        var elmnt = document.getElementById("main-content");
        elmnt.scrollIntoView();
    }

}

export default VideoItem;