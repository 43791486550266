import React from "react";
import {Link} from "react-router-dom";
import ArticleCarousel from "../page_parts/ArticleCarousel";
import * as constants from "../../constants/index";
import {addEndingSlashToSlug} from '../../utils/general';
import Axios from "axios";
import SanitizedContent from "../page_parts/SanitizedContent";
import MainChart from "../page_parts/MainChart";

class HomePage extends React.Component {

    constructor(props) {
      super(props)

      this.state = {
          page: null
      }
      
    }

      
    render() {

      const homePage = this.state.page;
      
      if (!homePage) {
          console.log('no page content yet');
          return null;
      }

      let homeImage = process.env.PUBLIC_URL + "images/banners/tara_home_banner.jpg";

      if (homePage.featured_image) {
        homeImage = constants.CMS_URL + homePage.featured_image.url;
      }

      const pages = this.props.allPages;

      return (
            <main id="main-content" className="home-layout">
                <div className="video-banner">
                  <div className="container content-box-wrapper">
                    <div className="content-box">
                      <SanitizedContent content={homePage.description} />
                      <Link className="btn btn-primary"  to={addEndingSlashToSlug(constants.TRIALS_PARENT_SLUG)}>Find out more</Link>
                    </div>
                  </div>
                  <div className="video-container">
                    <div className="d-none d-sm-block">
                      <div className="centered-image">
                        <img src={homeImage} className="poster" alt="TARA Project" />
                      </div>
                    </div>
                    <div className="d-block d-sm-none centered-image">
                      <img className="poster" src={homeImage} alt="TARA Project" />
                    </div>
                  </div>
                </div>
                <MainChart pages={pages} includeTitle={true} description={this.state.page.secondary_title} />
                <section className="cards-wrapper wp-card-content-slider">
                  <div className="container">
                    <h2>Latest News</h2>
                    <div className="row zero-xs-margin">
                      <ArticleCarousel />
                    </div>
                  </div>
                </section>
            </main>
        )
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = 'Home - Tara Project';
        this.__loadPageContent();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    __loadPageContent = () => {

      const currentSlug = '/';

      Axios.get(constants.PAGES_API_URL + '?slug='+currentSlug)
          .then(
              (result) => {
                  let pageData = result.data;

                  //if data is an array work with that. 
                  if (Array.isArray(result.data) && result.data.length > 0) {
                      pageData = result.data[0];
                  }

                  if (this._isMounted) {
                      this.setState({
                          page: pageData
                      });
                  }
                  
              }
          )
          .catch((error) => {
              // handle error
              console.log('Error fetching the page content: ', error);
          }
      );
  }

}

export default HomePage;