import React from "react";
import Title from '../page_parts/Title';
import PageContent from '../page_parts/PageContent';
import Breadcrumb from '../page_parts/Breadcrumb';
import Axios from "axios";
import * as constants from '../../constants';
import {getValidSlug} from '../../utils/general';


class ArticlePageSingle extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            allPages: this.props.allPages,
            page: null
        }
    }

    render() {

        const page = this.state.page;

        if (!page) {
            console.log('no page content yet');
            return null;
        }

        //if article has excerpt but not content show that
        if (!page.description && page.excerpt) {
            page.description = page.excerpt;
        }

        return (
            <>
                <Breadcrumb allPages={this.state.allPages} extra={page} />
                <Title currentPage={page} />
                <PageContent currentPage={page} />
            </>

        )

    }

    componentDidMount() {
        this.__loadPageContent();
    }
    

    __loadPageContent = () => {

        const currentSlug = getValidSlug(window.location.pathname);

        Axios.get(constants.ARTICLES_API_URL + '?slug=' + currentSlug)
            .then(
                (result) => {
                    let pageData = result.data;

                    if (!pageData || pageData.length === 0) {
                        //no page found: redirect
                        return this.setState({
                            redirect: true
                        })
                    }

                    //if data is an array work with that. 
                    if (Array.isArray(result.data) && result.data.length > 0) {
                        pageData = result.data[0];
                    }

                    document.title = pageData.title + ' - Tara Project';

                    this.setState({
                        page: pageData
                    });
                }
            )
            .catch((error) => {
                // handle error
                console.log(error);
            }
        );
    }

}




export default ArticlePageSingle;