import React from "react";
import * as constants from '../../constants';
import {Link} from "react-router-dom";
import {addEndingSlashToSlug, extractExcerptContent, getPictureURL} from '../../utils/general';
import SanitizedContent from "./SanitizedContent";

class TeamMemberItem extends React.Component{

    render () {

        const teamMember = this.props.teamMember;

        if (!teamMember) {
            return null;
        }
        
        let slug = addEndingSlashToSlug(teamMember.slug);
        slug = '/' + constants.TEAM_MEMBERS_PARENT_SLUG + '/' + slug;

        let picture = '';
        if (teamMember.hasOwnProperty("picture") && teamMember['picture']) {
            let pictureURL = getPictureURL(teamMember['picture'], 'medium');
            picture = <img alt={teamMember.name} src={constants.CMS_URL + pictureURL} />
        }

        let content = '';

        if (this.props.type === 'full') {
            content = this.__getMemberFullPageContent(picture, slug, teamMember);
        } else {
            content = this.__getMemberItemContent(picture, slug, teamMember);
        }

        return content;
    }

    __getMemberItemContent = (picture, slug, teamMember) => {

        let shortContent = (teamMember.short_biography && teamMember.short_biography.trim().length > 0) ? teamMember.short_biography.trim(): extractExcerptContent(teamMember.biography, 250) ;
        shortContent = <p>{shortContent} &nbsp; <Link className="cta-link" to={slug} >Full bio</Link></p>

        return (
            <div className="media-listing col-sm wp-consultant">
                <div className="media">
                    <div className="media-left">
                        <Link to={slug} >{picture}</Link>
                    </div>
                    <div className="media-body">
                        <h2 className="media-heading"><Link to={slug} >{teamMember.name}</Link></h2>
                        {shortContent}
                        {this.__extractMemberContact(teamMember)}
                    </div>
                </div>
            </div>
        )
    }

    __getMemberFullPageContent= (picture, slug, teamMember) => {
        let fullContent = <div className="full-team-member-content"><SanitizedContent content={teamMember.biography} /></div>
        return (
            <div className="media-listing col-sm wp-consultant cvri-consultant-full">
                <div className="media">
                    <div className="media-left">
                        <Link to={slug} >{picture}</Link>
                    </div>
                    <div className="media-body">
                        {this.__extractMemberContact(teamMember)}
                    </div>
                </div>
                {fullContent}
            </div>
        )
    }


    __extractMemberContact = (teamMember) => {
        let telephones = [];
        let emails = [];

        if (!teamMember.contact) {
            return null;
        }

        const TELEPHONE_TYPE = 'telephone';
        const EMAIL_TYPE = 'email';

        teamMember.contact.forEach(element => {
            if (element.type === TELEPHONE_TYPE) {
                telephones.push(element.value);
            } else if(element.type === EMAIL_TYPE) {
                emails.push(element.value);
            }
        });

        //telephones
        let dataTelephones = telephones.join('\n').split('\n').map((val, index) => {
            return <span key={index}><a href={'tel: ' + val.replace(/ /g, "") }>{val}</a><br /></ span>
        });

        if (dataTelephones.length > 0) {
            dataTelephones = <li className="cvri-team-top-contact"><strong>Telephone: &nbsp;</strong> {<span className="cvri-team-contact">{dataTelephones} </ span>}</li>
        }

        //emails
        let dataEmails = emails.join('\n').split('\n').map((val, index) => {
            return <span key={index}><a href={'mailto: ' + val }>{val}</a><br /></ span>
        });

        if (dataEmails.length > 0) {
            dataEmails = <li className="cvri-team-top-contact"><strong>Email: &nbsp;</strong> {<span className="cvri-team-contact">{dataEmails} </ span>}</li>
        }

        let contactData = (
            <ul>
                {dataTelephones}
                {dataEmails}
            </ul>
        );  

        return contactData;

    }

}

export default TeamMemberItem;