import React from "react";
import * as constants from '../../constants';
import Title from '../page_parts/Title';
import Breadcrumb from '../page_parts/Breadcrumb';
import axios from "axios";
import {getPageBySlug, urlQuerySearchToObject, getValidSlug} from '../../utils/general';
import TaraPagination from '../page_parts/TaraPagination';
import { withRouter} from "react-router-dom";

class PublicationsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            allPages: this.props.allPages,
            page: null,
            items: [],
            total: 0,
        }

        this.pageLimit = 10;
        this.currentPgNo = 1;
        this.appliedTags = this.__getAppliedTags();
    }

    render() {

        if (!this.state.page) {
            return null;
        }

        return (
            <>
                <Breadcrumb allPages={this.state.allPages} />
                <Title currentPage={this.state.page} />
                {this.__getContent()}
            </>

        )
    }


    componentDidMount() {
        this._isMounted = true;
        this.__loadPageContent(this.pageLimit);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    __getContent = () => {
        
        let content = [];
        for (const [index, item] of this.state.items.entries()) {

            const description = (item.description)? <p><b>Description:</b> {item.description}</p>: '';
            let publication_date = '';
            if (item.publication_date) {
                let pub_date = new Date(item.publication_date);
                let pub_date_formated = pub_date.toLocaleString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                publication_date = <p><b>Publication Date</b>: {pub_date_formated}</p>;
            }
            let itemLink = '';
            let itemFiles = [];
            let itemTags = [];

            if (item.link) {
                itemLink = <a className="btn btn-secondary btn-forum btn-file-download" target="_blank" rel="noreferrer" href={item.link}>Get to Publication</a>
            }

            if (item.file) {
                itemFiles = item.file.map((val, index) => {
                    let fileTitle = (val.name)? val.name: val['file']['name'];
                    let fileUrl = constants.CMS_URL + val['file']['url'];
                    return <a key={index} className="btn btn-secondary btn-file-download" href={fileUrl} onClick={(e) => {this.__download(fileUrl, val['file']['name']); e.preventDefault()}} >{fileTitle}</a>;
                }).filter(Boolean);
            }

            if (item.tags.length > 0) {
                itemTags = item.tags.map((val, index) => {
                    let tag = val.tag;
                    return <span key={index} className="btn btn-secondary btn-mpn-tag" onClick={(e) => {this.__addRemoveTagFromUrl(e, 'add')}} >{tag}</span>;
                }).filter(Boolean);
            }

            content.push(
                (
                <div className="results-info" key={index}>
                    <h3>{item.title}</h3>
                    
                    <div className="article-content">
                        {publication_date}
                        {description}
                        <p><b>Tags: </b>{itemTags}</p>
                        <br />
                        {itemLink}
                        {itemFiles}
                    </div>
                    <div className="clear"></div>
                </div>
                )
            )
        }

        //applied tags
        let appliedTags = this.__getAppliedTags();
        appliedTags = appliedTags.map((value, index) => {
            return (
                <span className="applied-tag btn btn-secondary btn-mpn-tag" key={index} onClick={(e) => {this.__addRemoveTagFromUrl(e, 'remove')}}>{value}</span>
            )
        });


        return (
           <main id="main-content" className="inner-main spotlight-landing-layout template-no-gaps col-sm articlespage">
                <section id="spotlight-area" className="container">
                <div id="publications-applied-tags">
                    <h4>Applied Tags: {appliedTags}</h4>
                </div>
                <div>
                    {content}
                    <TaraPagination currentPgNo={this.currentPgNo} type="publications" total={this.state.total} pageLimit={this.pageLimit} loadPageContent={this.__loadPageContent} />
                </div>
            </section>
        </main>
        )
    }

    __getAppliedTags = () => {
        let tags = [];
        let querySearchObj = urlQuerySearchToObject();
        if (querySearchObj.hasOwnProperty('tags')) {
            tags = JSON.parse(querySearchObj.tags);
        }
        return tags
    }

    __addRemoveTagFromUrl = (e, type="add") => {
        let tag = e.target.textContent;

        let querySearchObj = urlQuerySearchToObject();

        let newTagsProperty = [tag];
       
        if (querySearchObj.hasOwnProperty('tags')) {
            newTagsProperty = JSON.parse(querySearchObj.tags);
            if (type === 'add') {
                if (!newTagsProperty.includes(tag)) {
                    newTagsProperty.push(tag);
                }
            } else {
                newTagsProperty = newTagsProperty.filter(value => value !== tag)
            }
        }
        this.appliedTags = newTagsProperty;
        this.__loadPageContent(this.pageLimit)
        newTagsProperty = JSON.stringify(newTagsProperty);
        querySearchObj['tags'] = newTagsProperty;
        let queryString = Object.keys(querySearchObj).map(key => key + '=' + querySearchObj[key]).join('&');
        this.props.history.push("?" + queryString);
        
    }

    //force windows to open save as .
    __download = (url, title) => { 
        axios({ 
            url:url, 
            method:'GET', 
            responseType: 'blob' 
        }) 
        .then((response) => { 

            if(window.navigator.msSaveOrOpenBlob) {
                // IE11
                window.navigator.msSaveOrOpenBlob(new Blob([response.data]), title);
            } else {

                // Google chome, Firefox, ....
                const url = window.URL 
                .createObjectURL(new Blob([response.data])); 
                    const link = document.createElement('a'); 
                    link.href = url; 
                    link.setAttribute('download', title); 
                    document.body.appendChild(link); 
                    link.click(); 
            }
        }) 
    }

    __loadPageContent = (limit, page=1) => {

        let queryDataTypes = [this.__getItems(page, limit)];

        //query the total items only if we have no total
        //update always get the total items, as tags may be applied.
        queryDataTypes.push(this.__getTotalItems());

        if (!this.state.page) {
            queryDataTypes.push(this.__getCurrentPageContent())
        }

        axios.all(queryDataTypes)
        .then((results) => {
            if (this._isMounted) {

                let toState = {
                    items: results[0].data,
                }

                //add total only if it exists
                if (results.length > 1) {
                    toState.total = results[1].data
                }

                if (results.length > 2 && results[2].data.length > 0) {
                    toState.page = results[2].data[0]
                } else if (!this.state.page) {
                    toState.page = getPageBySlug(window.location.pathname, this.state.allPages)
                }

                this.currentPgNo = page;

                this.setState(toState);
            }
        })
        .catch((error) => {
            console.log('Error loading articles: ', error);
        });

    }

    __getItems(pgStart, limit) {
        let tags = this.appliedTags
        tags = JSON.stringify(tags)
        return axios.get(encodeURI(`${constants.PUBLICATIONS_API_URL}?page=${pgStart}&limit=${limit}&tags=${tags}`));
    }

    __getCurrentPageContent() {
        const currentSlug = getValidSlug(window.location.pathname);
        return axios.get(encodeURI(constants.PAGES_API_URL + '?slug='+currentSlug));
    }

    __getTotalItems() {
        let tags = this.appliedTags
        tags = JSON.stringify(tags)
        return axios.get(constants.PUBLICATIONS_API_URL + '?get_total=true&tags=' + tags);
    }

}

export default withRouter(PublicationsPage);


