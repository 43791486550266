import React from "react";
import * as constants from '../../constants';
import {Link} from "react-router-dom";
import {addEndingSlashToSlug, extractExcerptContent, getPictureURL} from '../../utils/general';


class GalleryArticleItem extends React.Component {



    render() {
        const article = this.props.data;
        const articleSRC = (article.image)?`${constants.CMS_URL}${getPictureURL(article.image, 'small')}`: false;
        const articleIMG = (articleSRC)? <img src={articleSRC} alt={article.title} className=" lazyloaded" />: '';
        const maxExcerptLength = 180

        let artContent = (article.excerpt && article.excerpt.trim().length > 0) ? article.excerpt.trim().substr(0, maxExcerptLength): extractExcerptContent(article.description, maxExcerptLength) ;
        artContent = (artContent.length > maxExcerptLength) ? artContent + '...': artContent;

        return (
            <div className="home-article">
                <Link to={constants.NEWS_PARENT_SLUG + '/' + addEndingSlashToSlug(article.slug)} className="spotlight fixed-lines">
                    <div className="home-article-image">
                        {articleIMG}
                    </div>
                    
                    <div className="spot-content">
                        <span className="d-none spot-label" />
                        <span className="spot-title">{article.title}</span>
                        <p>{artContent}</p>
                    </div>
                </Link>
            </div>
        )
    }
}

export default GalleryArticleItem;