import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as constants from '../../constants';
import axios from "axios";
import Slider from "react-slick";
import GalleryArticleItem from "./GalleryArticleItem";

class ArticleCarousel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            articles: []
        }
    }

    render() {

        if (!this.state.articles){
            return;
        }

        var settings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  arrows: false,
                  dots: true,
                  autoplay: true,
                  autoplaySpeed: 4000,
                  infinite: true
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  arrows: false,
                  dots: true,
                  autoplay: true,
                  autoplaySpeed: 4000,
                  infinite: true
                }
              }
            ]
          };

          const slides =this.state.articles.map((article, index) =>{
            return <GalleryArticleItem key={index} mykey={index} data={article} />
          });

          //fix react-slick bug: on mobile it shows the last item rather than the first.
          if (!slides || slides.length === 0) {
              return null;
          }

          //the slider will multiply the article
          return (
            <div id="cardContentSlider" className="carousel slide multi-item-carousel">
                <Slider {...settings}>
                    {slides}
                </Slider>
            </div>
          );
    }

    componentDidMount() {
        axios.get(constants.ARTICLES_API_URL + '?_limit=10&_sort=published_at:DESC')
        .then((results) => {
            this.setState({
                articles: results.data
            });

            //we scroll to the page time line if we need to, because the articles that come later will push the content down
            this.__scrollToHomePageTimeline();

        })
        .catch((error) => {
            console.log('Error loading initial data: ', error);
        });
    }

    __scrollToHomePageTimeline() {
          //do we scroll to the main summary page
          if (window.location.hash.length > 0 && window.location.hash === '#timeline-main-summary') {
            let timeLineMainSummary = document.getElementById(window.location.hash.replace('#', ''));
            if (timeLineMainSummary) {
              timeLineMainSummary.scrollIntoView()
            }
        }
    }

}

export default ArticleCarousel;