import React from "react";
import Title from '../page_parts/Title';
import SanitizedContent from '../page_parts/SanitizedContent';
import Breadcrumb from '../page_parts/Breadcrumb';
import Axios from "axios";
import * as constants from '../../constants';
import {getValidSlug} from '../../utils/general';


class ContactPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            allPages: this.props.allPages,
            page: null
        }
    }

    render() {
        
        if (!this.state.page) {
            console.log('no page content yet');
            return null;
        }

        return (
            <>
                <Breadcrumb allPages={this.state.allPages} />
                <Title currentPage={this.state.page} />
                {this.__getContactPageContent()}
            </>

        )

    }

    componentDidMount() {
        this.__loadPageContent();
    }
    

    __loadPageContent = () => {

        const currentSlug = getValidSlug(window.location.pathname);

        Axios.get(constants.PAGES_API_URL + '?slug='+currentSlug)
            .then(
                (result) => {
                    let pageData = result.data;

                    if (!pageData || pageData.length === 0) {
                        //no page found: redirect
                        return this.setState({
                            redirect: true
                        })
                    }

                    //if data is an array work with that. 
                    if (Array.isArray(result.data) && result.data.length > 0) {
                        pageData = result.data[0];
                    }

                    this.setState({
                        page: pageData
                    });
                }
            )
            .catch((error) => {
                // handle error
                console.log('Error fetching the page content: ', error);
            }
        );
    }

    __getContactPageContent = () => {

        let address = this.__getContactItem('address');
        let tel = this.__getContactItem('telephone');
        let email = this.__getContactItem('email');
        let map = this.__getContactItem('map');

        return (
            <main className="inner-main inner-master">
                <div className="container">
                <div className="row">
                    <div id="main-content" className="col-sm-12">
                    <article id="standard" className="standard-content standard-content-layout">
                        <div className="contact-map-view">
                            <div className="content-box">
                                <h2>Cardiovascular Research Institute Dublin</h2>
                                <address>
                                    {address.value.split("\n").map((i, key) => {
                                        return (
                                            <div key={key}>
                                                {i}
                                                <br />
                                            </div>
                                            )
                                    })}
                                </address>
                                <div className="phone-numbers">
                                <span className="d-block">
                                <a id="ctl00_ctl00_p_lt_mainBody_main_wpzTop_ContactMap_hlTelephone" href={"Tel:" + tel.value.replace(/ /g, "")}>{tel.value}</a>
                                </span>
                                <span className="d-block">
                                </span>
                                </div>
                                <span className="d-block">
                                <a id="ctl00_ctl00_p_lt_mainBody_main_wpzTop_ContactMap_hlEmail" href={"mailto:" + email.value}>{email.value}</a>
                                </span>
                            </div>
                            <div className="map-box" dangerouslySetInnerHTML={{__html:map.value}}></div>
                        </div>
                        <SanitizedContent content={this.props.contact.description} />
                    </article>
                    </div>
                </div>
                </div>
            </main>
        )
       }


       __getContactItem = (type) => {
           const contactItem = this.props.contact.body.map((ctItem, index) => {
                return (ctItem.type === type)?ctItem: null;
           }).filter(Boolean);

           if (contactItem.length > 0) {
               return contactItem[0];
           }

           return false;

       }

}




export default ContactPage;