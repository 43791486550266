import React from "react";
import * as constants from '../../constants';
import {Link} from "react-router-dom";
import {addEndingSlashToSlug, getPageByID, getPageBySlug} from '../../utils/general';

class MainChart extends React.Component {

    render() {
        const chart_pg_1 = getPageByID(constants.CHART_SECTION_1_ID, this.props.pages);
        const chart_pg_2 = getPageByID(constants.CHART_SECTION_2_ID, this.props.pages);
        const chart_pg_3 = getPageByID(constants.CHART_SECTION_3_ID, this.props.pages);
        const chart_pg_4 = getPageByID(constants.CHART_SECTION_4_ID, this.props.pages);
        const chart_pg_5 = getPageByID(constants.CHART_SECTION_5_ID, this.props.pages);
        const chart_pg_6 = getPageByID(constants.CHART_SECTION_6_ID, this.props.pages);

        const trialsPage = getPageBySlug(constants.TRIALS_PARENT_SLUG, this.props.pages);
        trialsPage.slug = addEndingSlashToSlug(trialsPage.slug)

        const title = (this.props.includeTitle)? <h2><Link to={trialsPage.slug}>The TARA Project</ Link></h2> : ''
        const description = (this.props.description)? <p>{this.props.description}</p> : ''

        return (
            <div className="container-timeline" id="timeline-main-summary">
            {title}
            <p>We look forward to receiving any feedback on our website, if you notice any studies which you think should be included, we would be delighted to hear from you – <a className="btn btn-light p-1" href="mailto: cvri@materprivate.ie">Click here</a></p>
            {description}
            <div className="overflow-timeline d-flex">
              <div className="home-mpn-timeline-buttons container tara-home-research">
                <div className=" wp-image-title-spotlight tara-research-section">
                    <Link to={"/" + addEndingSlashToSlug(constants.TRIALS_PARENT_SLUG) + chart_pg_1.slug} className="spotlight image-overlay fixed-lines spotlight-minimal">
                      <div className="spot-content ">
                        <span className="btn btn-secondary tara_colour_6" dangerouslySetInnerHTML={{__html: chart_pg_1.title.replace('(', '<span>(').replace(')', ')</span>')}}></span>
                        <p className="d-none" />
                      </div>
                    </Link>
                  </div>
                  <div className=" wp-image-title-spotlight tara-research-section">
                    <Link to={"/" + addEndingSlashToSlug(constants.TRIALS_PARENT_SLUG) + chart_pg_2.slug} className="spotlight image-overlay fixed-lines spotlight-minimal">
                      <div className="spot-content ">
                        <span className="btn btn-secondary tara_colour_3" dangerouslySetInnerHTML={{__html: chart_pg_2.title.replace('(', '<span>(').replace(')', ')</span>')}}></span>
                        <p className="d-none" />
                      </div>
                    </Link>
                  </div>
                  <div className=" wp-image-title-spotlight tara-research-section">
                    <Link to={"/" + addEndingSlashToSlug(constants.TRIALS_PARENT_SLUG) + chart_pg_3.slug} className="spotlight image-overlay fixed-lines spotlight-minimal">
                      <div className="spot-content ">
                        <span className="btn btn-secondary tara_colour_1" dangerouslySetInnerHTML={{__html: chart_pg_3.title.replace('(', '<span>(').replace(')', ')</span>')}}></span>
                        <p className="d-none" />
                      </div>
                    </Link>
                  </div>
                  <div className=" wp-image-title-spotlight tara-research-section">
                    <Link to={"/" + addEndingSlashToSlug(constants.TRIALS_PARENT_SLUG) + chart_pg_4.slug} className="spotlight image-overlay fixed-lines spotlight-minimal">
                      <div className="spot-content ">
                        <span className="btn btn-secondary tara_colour_2" dangerouslySetInnerHTML={{__html: chart_pg_4.title.replace('(', '<span>(').replace(')', ')</span>')}}></span>
                        <p className="d-none" />
                      </div>
                    </Link>
                  </div>
                  <div className=" wp-image-title-spotlight tara-research-section">
                    <Link to={"/" + addEndingSlashToSlug(constants.TRIALS_PARENT_SLUG) + chart_pg_5.slug} className="spotlight image-overlay fixed-lines spotlight-minimal">
                      <div className="spot-content ">
                        <span className="btn btn-secondary tara_colour_8 " dangerouslySetInnerHTML={{__html: chart_pg_5.title.replace('(', '<span>(').replace(')', ')</span>')}}></span>
                        <p className="d-none" />
                      </div>
                    </Link>
                  </div>
                  <div className=" wp-image-title-spotlight tara-research-section">
                    <Link to={"/" + addEndingSlashToSlug(constants.TRIALS_PARENT_SLUG) + chart_pg_6.slug} className="spotlight image-overlay fixed-lines spotlight-minimal">
                      <div className="spot-content ">
                        <span className="btn btn-secondary tara_colour_7 last" dangerouslySetInnerHTML={{__html: chart_pg_6.title.replace('(', '<span>(').replace(')', ')</span>')}}></span>
                        <p className="d-none" />
                      </div>
                    </Link>
                  </div>
              </div>
              <div>
                <img className="mpn-timeline" src={process.env.PUBLIC_URL + '/images/timeline.png?v1=4'} alt="Tara Project Timeline" />
              </div>
            </div>                  
          </div>
        )
    }

}

export default MainChart;