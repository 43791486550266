import React from "react";
import Title from '../page_parts/Title';
import Breadcrumb from '../page_parts/Breadcrumb';
import SanitizedContent from '../page_parts/SanitizedContent';
import Axios from "axios";
import * as constants from '../../constants';
import {getValidSlug} from '../../utils/general';
import MainChart from "../page_parts/MainChart";


class TrialMainPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            allPages: this.props.allPages,
            page: null,
            trials: []
        }
    }

    render() {

        if (!this.state.page) {
            console.log('no page content yet');
            return null;
        }

        let currentPageDescription = this.state.page.description;
        let pageContent = (currentPageDescription)? currentPageDescription: '';

        return (
            <>
                <Breadcrumb allPages={this.state.allPages} />
                <Title currentPage={this.state.page} />
                <main id="main-content" className="inner-main spotlight-landing-layout template-no-gaps">
                    <section id="spotlight-area" className="container">
                        <SanitizedContent content={pageContent} />
                        <MainChart pages={this.state.allPages} includeTitle={false} />
                    </section>
                    <div className="clear"></div>
                </main>
            </>

        )

    }

    componentDidMount() {
        this._isMounted = true;
        this.__loadPageContent();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    

    __loadPageContent = () => {

        const currentSlug = getValidSlug(window.location.pathname);

        Axios.get(constants.PAGES_API_URL + '?slug='+currentSlug)
            .then(
                (result) => {
                    let pageData = result.data;

                    if (!pageData || pageData.length === 0) {
                        //no page found: redirect
                        return this.setState({
                            redirect: true
                        })
                    }

                    //if data is an array work with that. 
                    if (Array.isArray(result.data) && result.data.length > 0) {
                        pageData = result.data[0];
                    }

                    if (this._isMounted) {
                        this.setState({
                            page: pageData,
                        });
                    }
                    return pageData
                }
            ).catch((error) => {
                // handle error
                console.log('Error fetching the page content: ', error);
            }
        );
    }
}

export default TrialMainPage;