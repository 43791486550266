import React from "react";
import * as constants from '../../constants';
import Title from '../page_parts/Title';
import Breadcrumb from '../page_parts/Breadcrumb';
import axios from "axios";
import {getValidSlug} from '../../utils/general';
import TeamMemberItem from "../page_parts/TeamMemberItem";
import TaraPagination from "../page_parts/TaraPagination";


class TeamMembersPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            allPages: this.props.allPages,
            page: null, //rather than querying the db to set this, we set it from the props.allPages as we don't need content of the page.
            teamMembers: [],
            total: 0,
        }

        this.pageLimit = 10;
        this.currentPgNo = 1;
    }

    render() {

        if (!this.state.page) {
            return null;
        }

        return (
            <>
                <Breadcrumb allPages={this.state.allPages} />
                <Title currentPage={this.state.page} />
                {this.__getContent()}
            </>

        )
    }

    componentDidMount() {
        this._isMounted = true;
        this.__loadPageContent(this.pageLimit);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    __loadPageContent = (limit, page=1) => {

        const start = (page === 1)?0: page * limit - limit;
        //rather than getting the page name from the all pages api, get it here to include the featured_image. 
        let queryDataTypes = [this.__getTeamMembers(start, limit), this.__getPage()];

        //query the total items only if we have no total
        if (!this.state.total) {
            queryDataTypes.push(this.__getTotalTeamMembers());
        }

        axios.all(queryDataTypes)
        .then((results) => {
            if (this._isMounted) {

                let toState = {
                    teamMembers: results[0].data,
                    page: page
                }
                // //if no current page, add it
                if (!this.state.page) {
                    toState.page = results[1].data[0]
                }

                //add total only if it exists
                if (results.length > 2) {
                    toState.total = results[2].data
                }

                this.currentPgNo = page;

                this.setState(toState);
            }
        })
        .catch((error) => {
            console.log('Error loading articles: ', error);
        });

    }

    __getPage() {
        const currentSlug = getValidSlug(window.location.pathname);
        return axios.get(encodeURI(constants.PAGES_API_URL + '?slug='+currentSlug));
    }

    __getTeamMembers(pgStart, limit) {
        return axios.get(encodeURI(`${constants.TEAM_MEMBERS_API_URL}?_start=${pgStart}&_limit=${limit}`));
    }

    __getTotalTeamMembers() {
        return axios.get(constants.TEAM_MEMBERS_API_URL + '/count');
    }

    __getContent = () => {

        let content = [];
        for (const [index, teamMember] of this.state.teamMembers.entries()) {
            content.push(<TeamMemberItem teamMember={teamMember} key={index} />)
        }

        return (
            <main id="main-content" className="inner-main spotlight-landing-layout template-no-gaps col-sm">
                 <section id="spotlight-area" className="container">
                 <div className="media-listing overview-layout">
                    {content}
                    <TaraPagination currentPgNo={this.currentPgNo} type="team members" total={this.state.total} pageLimit={this.pageLimit} loadPageContent={this.__loadPageContent} />
                 </div>
             </section>
         </main>
         );

    }

} 

export default TeamMembersPage;