import React from "react";
import FooterSection from './FooterSection';
import {Link} from "react-router-dom";

class Footer extends React.Component {


    render() {

        const sections = this.props.data.body;

        const footerMainSections = sections.map((sectionItem, index) =>{
            return <FooterSection key={index} data={sectionItem}/>;
        });

        return (
            <footer>
                <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row footerTopSection">
                            {footerMainSections}
                        </div>
                    </div>
                </div>
                <p className="last-row">
                    <Link to="/privacy-policy/">Privacy Policy &amp; Cookies Settings </Link>
                </p>
                </div>
            </footer>
        )
    }
}

export default Footer;