import React from "react";
import {Link} from "react-router-dom";

class TaraPagination extends React.Component {

    render() {

        let currentPgNo = this.props.currentPgNo;
        const pageLimit = this.props.pageLimit;
        const totalDBEntries = this.props.total;
        const paginationType = this.props.type;

        if (totalDBEntries <= pageLimit) {
            return null;
        }

        let totalPages = Math.ceil(totalDBEntries / pageLimit);

        //generate pages links
        let pagesLinks = [];

        for (let pgNo = 1; pgNo <= totalPages; pgNo++) {

            let firstPage = (pgNo === 1)?'cvri-first-page': '';            
            if (pgNo === currentPgNo) {
                pagesLinks.push(<strong key={pgNo} className={"active-page " + firstPage}>{pgNo}</strong>)
            } else {
                pagesLinks.push(<Link key={pgNo} to="/" className={firstPage} onClick={(e) => this.activatePagingLinks(e, pgNo)}>{pgNo}</Link>);
            }
        }

        let startResultItemIndex = (currentPgNo=== 1) ? currentPgNo: currentPgNo * pageLimit - pageLimit + 1;
        let endResultItemIndex =  (currentPgNo * pageLimit);
        endResultItemIndex = (endResultItemIndex > totalDBEntries) ? totalDBEntries: endResultItemIndex;

        return (
            <div className="cvri-paging">
            <span className="summary">
                {`${startResultItemIndex} - ${endResultItemIndex} of ${totalDBEntries} ${paginationType}`}
            </span>
            <div className="links-wrapper">    
                <Link className={`icon-left  ${currentPgNo === 1?'disabled': ''}`} onClick={(e) => this.activatePagingLinks(e, currentPgNo - 1)}  to="/">back</Link>
                {pagesLinks}
                <Link className={`icon-right ${currentPgNo === totalPages ?'disabled':''}`} onClick={(e) => this.activatePagingLinks(e, currentPgNo + 1)}  to="/">Next</Link>
            </div>
            </div>
            
        )
    }
    

    activatePagingLinks = (event, currentPgNo) => {

        setTimeout(function() {
            let mainContent = document.getElementById('cvri_wrapper');
            if (mainContent) {
                // scroll to the top of the page. 
                mainContent.scrollIntoView(true);
            }
        }, 500);

        //check if activated page links is overwrittern 
        if (this.props.activatePagingLinks && typeof this.props.activatePagingLinks === 'function') {
            this.props.activatePagingLinks(event, currentPgNo);
            return;
        }

        const limit = this.props.pageLimit;
        
        if (!event.target.classList.contains('disabled')){
            
            this.props.loadPageContent(limit, currentPgNo);
        }

        
        event.preventDefault();
    }

}

export default TaraPagination;