let environment = process.env.NODE_ENV;

let cms_url = 'http://localhost:1698';
// cms_url = 'https://mpn-tara-project.northeurope.cloudapp.azure.com/api';

let currentBase = window.location.protocol + '//' + window.location.hostname;

if (window.location.hostname === 'mphdev.northeurope.cloudapp.azure.com') {
    cms_url = currentBase + '/api';
} else if (environment === 'production' ) {
    cms_url = currentBase + '/api';
}

export const BASE_API_URL = cms_url + '/';
export const NAVIGATION_API_URL = BASE_API_URL + 'main-menu';
export const PAGES_API_URL = BASE_API_URL + 'pages/';
export const TRIAL_ITEMS_API_URL = BASE_API_URL + 'trials/';
export const PAGES_API_LIST = BASE_API_URL + 'pages/all';
export const FOOTER_API_URL = BASE_API_URL + 'footer';
export const ARTICLES_API_URL = BASE_API_URL + 'articles';
export const PUBLICATIONS_API_URL = BASE_API_URL + 'publications/get_by_tags';
export const TEAM_MEMBERS_API_URL = BASE_API_URL + 'teams';
export const CONTACT_API_URL = BASE_API_URL + 'contact';
export const SEARCH_API_URL = BASE_API_URL + 'cvrisearch';
export let CMS_URL  = cms_url;
export const SUB_PAGES_KEY = 'sub_pages';
export const CDN_JWPLAYER_URL = 'https://cdn.jwplayer.com/libraries/gKWBLnb9.js';
export const JWP_PLAYLIST_URL = `https://cdn.jwplayer.com/v2/playlists/VT4hf3EV`;

export const COLOUR_PANETONE_319 = '#00b9bd';
export const COLOUR_PANETONE_184 = '#f05c5b';
export const COLOUR_PANETONE_2583 = '#a35494';
export const COLOUR_PANETONE_541C = '#003C71';
export const COLOUR_PANETONE_GREY_6 = '#8f8984';

export const COLOURS = [COLOUR_PANETONE_184, COLOUR_PANETONE_319, COLOUR_PANETONE_2583, COLOUR_PANETONE_541C, COLOUR_PANETONE_GREY_6];


export var CURRENT_PATHNAME = window.location.pathname;