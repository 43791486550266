import React from "react";
import VideoItem from "../page_parts/VideoItem";
import {Link, withRouter} from "react-router-dom";
import * as constants from '../../constants';
import {getValidSlug, addEndingSlashToSlug} from '../../utils/general';
import ReactJWPlayer from 'react-jw-player';


class VideoPlaylist extends React.Component {

    constructor(props) {
        super(props);
        this.playerID = "fda3454d4";
    }

    render() {

        let playlist = this.props.playlist;

        if (!playlist || playlist.length === 0) {
            return null;
        }

        let currentVideo = playlist[0];
        let playVideo = false;
        let topMobileClass = '';

        if (this.props.currentVideo) {
            currentVideo = this.props.currentVideo;
            playVideo = true;
            playlist = this.__rearrangePlaylist(currentVideo, playlist);
            topMobileClass = 'full-video-container';
        }

        
        //we are already redirected so grab info about the first video
        let topVideoImages = currentVideo.images;
        let slug = '/' + constants.EDUCATION_PAGE + '/' + getValidSlug(currentVideo.slug);
        slug = addEndingSlashToSlug(slug);

        const playlistVideos = playlist.map((video, index) => {
            return <VideoItem key={index} currentVideo={video} />
        });

        //remove the first video from list below, as it's currently being played. 
        playlistVideos.shift();

        let topSection = (
                    <Link className="grid-item-image videoClick" to={slug}>
                        <div className="m_image_container">
                            <img alt={currentVideo.title} src={topVideoImages[topVideoImages.length - 1].src} />
                        </div>
                        <i className="play_icon" />
                    </Link>
        );
        
        if (playVideo) {
            topSection = (
                    <ReactJWPlayer
                        playerId={this.playerID}
                        playerScript={constants.CDN_JWPLAYER_URL}
                        isAutoPlay={true}
                        isMuted={false}
                        playlist={playlist}
                        onVideoLoad={this.onVideoLoad}
                        // onReady={this.onReady}

                    />  
            )   
        }


        return (
            <>
                <main id="main-content" className="inner-main spotlight-landing-layout template-no-gaps">
                    <section className={"container " + topMobileClass}>
                        <div className="row no-gutter m_main_video">
                            <div className="col-xs-12 col-md-8 main_video_sub">
                                <div id="m_main_video">{topSection}</div>    
                            </div>
                            <div className="col-xs-12 col-md-4">
                                <div className="card-component" id="m_video_details">
                                <div className="card-heading">
                                    <h3>{currentVideo.title}</ h3>
                                </div>
                                <div className="card-content">
                                    <p>{currentVideo.description}</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="spotlight-area" className="container">
                    <div>
                        <div className="row no-gutter m_videos">
                            {playlistVideos}
                        </div>
                    </div>
                    </section>
                </main>
            </>

        )
    }

    onVideoLoad = (event) => {

        // console.log('video loaded');
        var elmnt = document.getElementById("main-content");
        elmnt.scrollIntoView();

        //if ccurrent slug does not match the video slug, then redirect
        let currentVideo = event.item;

        let currentSlug = getValidSlug(window.location.pathname);
        if (currentSlug !== currentVideo.slug) {

            let newSlug = '/' + constants.EDUCATION_PAGE + '/' + currentVideo.slug + '/';
            this.props.history.push(newSlug);
        }    
    }

    /**
     * Rearranges the playlist so that the video from the url gets first in the playlist then whatever was after it, then whatever was before it..like a circle
     * @param {*} playlistItems 
     */
    __rearrangePlaylist = (currentVideo, playlistItems) =>{
        let playlist = [currentVideo];

        let currentVideoIndex = currentVideo.playlist_index;

        let first = [];

        let second = [];

        for (let video of playlistItems) {
            if (video.playlist_index < currentVideoIndex) {
                first.push(video);
            } else if (video.playlist_index > currentVideoIndex) {
                second.push(video);
            }
        }
        return playlist.concat(second).concat(first);
    }
}




export default withRouter(VideoPlaylist);