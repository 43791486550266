import React from "react";
import Title from '../page_parts/Title';
import Breadcrumb from '../page_parts/Breadcrumb';
import SanitizedContent from '../page_parts/SanitizedContent';
import Axios from "axios";
import * as constants from '../../constants';
import {getValidSlug, slugify} from '../../utils/general';
import Plotly from "plotly.js"
import createPlotlyComponent from 'react-plotly.js/factory';
import {Link} from "react-router-dom";
const Plot = createPlotlyComponent(Plotly);


class TrialPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            allPages: this.props.allPages,
            page: null,
            chart: null,
            trials: []
        }

        this.bubble_size_1 = 15
        this.bubble_size_2 = 30
        this.bubble_size_3 = 45
        this.bubble_size_4 = 65
        this.bubble_size_5 = 100

        this.bubble_background_1 = '#5ec1fa'
        this.bubble_background_2 = '#ce6599'
        this.bubble_background_3 = '#ff8e83'
        this.bubble_background_4 = '#11d8c3'
        this.bubble_background_5 = '#ffbb5d'
        this.bubble_background_6 = '#ffe9f4'

        this.bubble_border_1 = '#4882c7'
        this.bubble_border_2 = '#ff2600'
        this.bubble_border_3 = '#000000'
    }

    render() {

        if (!this.state.page) {
            console.log('no page content yet');
            return null;
        }

        let currentPageDescription = this.state.page.description;
        let pageContent = (currentPageDescription)? currentPageDescription: '';

        return (
            <>
                <Breadcrumb allPages={this.state.allPages} />
                <Title currentPage={this.state.page} />
                <main id="main-content" className="inner-main spotlight-landing-layout template-no-gaps">
                    <section id="spotlight-area" className="container">
                        <Link className="btn btn-secondary btn-forum go-to-trial" to={`/#timeline-main-summary`}>Go to Main Summary</Link>
                        <br />
                        <SanitizedContent content={pageContent} />
                        {this.state.chart}
                        <div id="mpn-chart-content"></div>
                    </section>
                    <div className="clear"></div>
                </main>
            </>

        )

    }

    shouldComponentUpdate(nextProps, nextState) {
        
        const contentDiv = document.getElementById("mpn-chart-content");
        
        if (nextState && nextState.page) {
            let activePage = nextState.page;
            let currentSlug = getValidSlug(window.location.pathname);
            if (activePage.slug !== currentSlug) {
                contentDiv.innerHTML = '';
                this.__loadPageContent();
            }
        } else if (!nextState) {
            this.__loadPageContent();
            contentDiv.innerHTML = '';
        }
        return true;
    }

    componentDidMount() {
        this._isMounted = true;
        this.__loadPageContent();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    

    __loadPageContent = () => {

        const currentSlug = getValidSlug(window.location.pathname);

        Axios.get(constants.PAGES_API_URL + '?slug='+currentSlug)
            .then(
                (result) => {
                    let pageData = result.data;

                    if (!pageData || pageData.length === 0) {
                        //no page found: redirect
                        return this.setState({
                            redirect: true
                        })
                    }

                    //if data is an array work with that. 
                    if (Array.isArray(result.data) && result.data.length > 0) {
                        pageData = result.data[0];
                    }

                    if (this._isMounted) {

                        let chart = this.__getChart(pageData)

                        this.setState({
                            page: pageData,
                            chart: chart
                        });
                    }
                    return pageData
                }
            ).then(pageData => {
                //if we have the page data, get the trials based on the page.
                Axios.get(`${constants.TRIAL_ITEMS_API_URL}?_where[page.id]=${pageData.id}&_limit=300` )
                .then(
                    (result) => {
                        let trials = result.data;
                        if (this._isMounted) {
                            this.setState({
                                trials: trials
                            });
                        }
                    }
                ).catch((error) => {
                    // handle error
                    console.log('Error fetching the trials content: ', error);
                })
            })
            .catch((error) => {
                // handle error
                console.log('Error fetching the page content: ', error);
            }
        );
    }

    
    __bubbleClick = (data) => {

        const contentDiv = document.getElementById("mpn-chart-content");

        if (!data || !data.hasOwnProperty('points') || data.points.length !== 1) {
            console.error("Something went wrong as the bubble clicked did not return anything.")
            contentDiv.innerHTML = ''
            return false
        }

        const bubble = data.points[0]
        let bubbleTitle = bubble.text

        let cleanBubbleTitle = bubbleTitle.replaceAll('<br />' , ' ').replaceAll('<br/>', ' ').replaceAll('<br >', ' ').replaceAll('  ', '')

        // const xAxis = bubble.x;
        // let cleanBubbleTitleNoYear = cleanBubbleTitle.replace(xAxis, '')

        // get the slugified title of the clicked bubble, then search within the trials data the same slugified title
        const slugifiedTitle = slugify(cleanBubbleTitle)

        let trials = this.state.trials.map((val, index) => {
            const slugifiedTrialTitle = slugify(val.title)

            if (slugifiedTitle === slugifiedTrialTitle) {
                return val
            }

            return null;

        }).filter(Boolean)

        if (!trials || trials.length === 0) {
            //we could not find the trial in the cms
            contentDiv.innerHTML = ''
            return false
        }

        const trial = trials[0]

        let description = trial.description;
        description = description.replaceAll('<a href="', '<a target="_blank" href="')
        
        contentDiv.innerHTML = `<h4>${trial.title}</h4> ${description}`;
        // contentDiv.scrollIntoView({behavior: 'smooth', block: 'center'});
        const y = contentDiv.getBoundingClientRect().top + window.scrollY;
        window.scroll({
            top: y-100,
            behavior: 'smooth'
        });
        
    }


    __getChart = (pageData) => {

        let plotData = '';

        if (pageData.id === constants.CHART_SECTION_1_ID) {
            plotData = this.__getPlot1()
        } else if (pageData.id === constants.CHART_SECTION_2_ID) {
            plotData = this.__getPlot2()
        } else if (pageData.id === constants.CHART_SECTION_3_ID) {
            plotData = this.__getPlot3()
        } else if (pageData.id === constants.CHART_SECTION_4_ID) {
            plotData = this.__getPlot4()
        } else if (pageData.id === constants.CHART_SECTION_5_ID) {
            plotData = this.__getPlot5()
        } else if (pageData.id === constants.CHART_SECTION_6_ID) {
            plotData = this.__getPlot6()
        }


        let chartContent = (
            <div>
                <div className="timeline-top-legend d-lg-flex flex-row justify-content-between mb-20">
                    <div>
                        <h4>Legend</h4>
                        <ul className="tara-timeline-legend">
                            <li><span className="legend_round_red"></span> ACS Population Only</li>
                            <li><span className="legend_round_blue"></span> Mixed ACS & CCS Population</li>
                            <li><span className="legend_round_black"></span> Secondary Prevention Population</li>
                        </ul>
                    </div>
                    <div>
                        <h4>Number of Patients per Trial</h4>
                        <img className="pt_per_trial" src={process.env.PUBLIC_URL + '/images/patients_per_trial.png'} alt="Number of Patients per Trial" />
                    </div>
                </div>
                {plotData} 
            </div>
        )

        return chartContent;
    }

    __getPlot6 = () => {
        return (
            <section className="tara-plot tara-plot-scrollable">
                <Plot
                    data={this.getPlot6Bubbles()}
                    onClick = {(e) => {this.__bubbleClick(e)}}
                    layout={ {autosize:false, showlegend: false, font: {size: 13},
                    width: 1140,
                    margin: {
                        l: 0,
                        r: 0,
                        b: 50,
                        t: 50,
                        pad: 0
                    },
                    paper_bgcolor: '#fff9fc',
                    xaxis: {
                        title: {
                            text: 'Year of Publication (Click the bubbles for details)',
                            font: {
                                size: 14,
                                color: '#7f7f7f'
                            }
                        },
                        type: 'category'
                    }, yaxis: {range: [3,8]},
                    title: {
                        // text:'Click bubbles for details',
                        font: {
                            size: 24
                        },
                        xref: 'paper',
                        y: 10
                    }} }
                    useResizeHandler={true}
                    config={{displayModeBar: true, y: [1],displaylogo: false}}
                    style={{width: "100%", height: "100%"}}
                />
            </section>
        )
    }

    __getPlot5 = () => {
        return (
            <section className="tara-plot tara-plot-scrollable">
                <Plot
                    data={this.getPlot5Bubbles()}
                    onClick = {(e) => {this.__bubbleClick(e)}}
                    layout={ {autosize:true, showlegend: false, font: {size: 13},
                    margin: {
                        l: 0,
                        r: 0,
                        b: 50,
                        t: 50,
                        pad: 0
                    },
                    paper_bgcolor: '#fff4e5',
                    xaxis: {
                        title: {
                            text: 'Year of Publication (Click the bubbles for details)',
                            font: {
                                size: 14,
                                color: '#7f7f7f'
                            }
                        },
                        type: 'category'
                    }, yaxis: {range: [3,8]},
                    title: {
                        // text:'Click bubbles for details',
                        font: {
                            size: 24
                        },
                        xref: 'paper',
                        y: 10
                    }} }
                    useResizeHandler={true}
                    config={{displayModeBar: true, y: [1],displaylogo: false}}
                    style={{width: "100%", height: "100%"}}
                />
            </section>
        )
    }

    __getPlot4 = () => {
        return (
            <section className="tara-plot">
                <Plot
                    data={this.getPlot4Bubbles()}
                    onClick = {(e) => {this.__bubbleClick(e)}}
                    layout={ {autosize:true, showlegend: false, font: {size: 13},
                    height: 350,
                    margin: {
                        l: 0,
                        r: 0,
                        b: 50,
                        t: 50,
                        pad: 0
                    },
                    title: {
                        // text:'Trials of Secondary Prevention',
                        font: {
                            size: 24
                        },
                        xref: 'paper',
                        y: 10
                    },
                    paper_bgcolor: '#e8faf8',
                    xaxis: {
                        title: {
                            text: 'Year of Publication (Click the bubbles for details)',
                            font: {
                                size: 14,
                                color: '#7f7f7f'
                            }
                        },
                        type: 'category'
                    },
                    yaxis: {range: [4,8]}} }
                    useResizeHandler={true}
                    config={{displayModeBar: true, y: [1],displaylogo: false}}
                    style={{width: "100%", height: "100%"}}
                />
            </section>
        )
    }

    __getPlot3 = () => {
        return (
            <section className="tara-plot">
                <Plot
                    data={this.getPlot3Bubbles()}
                    onClick = {(e) => {this.__bubbleClick(e)}}
                    layout={ {autosize:true, showlegend: false, font: {size: 13},
                    margin: {
                        l: 0,
                        r: 0,
                        b: 50,
                        t: 50,
                        pad: 0
                    },
                    title: {
                        // text:'Trials of Secondary Prevention',
                        font: {
                            size: 24
                        },
                        xref: 'paper',
                        y: 10
                    },
                    paper_bgcolor: '#fce6e4',
                    xaxis: {
                        title: {
                            text: 'Year of Publication (Click the bubbles for details)',
                            font: {
                                size: 14,
                                color: '#7f7f7f',
                            }
                
                        },
                        type: 'category'
                    },
                        yaxis: {range: [3,9]}} }
                    useResizeHandler={true}
                    config={{displayModeBar: true, y: [1],displaylogo: false}}
                    style={{width: "100%", height: "100%"}}
                />
            </section>
        )
    }

    __getPlot2 = () => {
        return (
            <section className="tara-plot">
                <Plot
                    data={this.getPlot2Bubbles()}
                    onClick = {(e) => {this.__bubbleClick(e)}}
                    layout={ {autosize:true, showlegend: false, font: {size: 13},
                    margin: {
                        l: 0,
                        r: 0,
                        b: 50,
                        t: 50,
                        pad: 0
                    },
                    title: {
                        // text:'Trials of Secondary Prevention',
                        font: {
                            size: 24
                        },
                        xref: 'paper',
                        y: 10
                    },
                    paper_bgcolor: '#fbe1ee',
                    xaxis: {
                        title: {
                            text: 'Year of Publication (Click the bubbles for details)',
                            font: {
                                size: 14,
                                color: '#7f7f7f',
                            }
                
                        },
                        type: 'category'
                    }, yaxis: {range: [1,10]}} }
                    useResizeHandler={true}
                    config={{displayModeBar: true, y: [1],displaylogo: false}}
                    style={{width: "100%", height: "100%"}}
                />
            </section>
        )
    }

    __getPlot1 = () => {
        return (
            <section className="tara-plot tara-plot-scrollable">
                <Plot
                    data={this.getPlot1Bubbles()}
                    onClick = {(e) => {this.__bubbleClick(e)}}
                    layout={ {autosize:false, showlegend: false, font: {size: 13},
                    width: 1140,
                    margin: {
                        l: 0,
                        r: 0,
                        b: 50,
                        t: 50,
                        pad: 0
                    },
                    title: {
                        // text:'Trials of Secondary Prevention',
                        font: {
                            size: 24
                        },
                        xref: 'paper',
                        y: 10
                    },
                    paper_bgcolor: '#dcf0fb',
                    xaxis: {
                        title: {
                            text: 'Year of Publication (Click the bubbles for details)',
                            font: {
                                size: 14,
                                color: '#7f7f7f',
                            }
                
                        },
                        // rangeslider: {visible: true, type: 'linear'},
                        type: 'category'
                    }, yaxis: {range: [1,10]}} }
                    useResizeHandler={false}
                    config={{displayModeBar: true, y: [1], displaylogo: false}}
                    
                />
            </section>
        )
    }

    getPlot5Bubbles = () => {
        let data = [
            {
                x: ['2001'],
                y: [5],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['CURE<br />2001'],
                mode:'markers+text',
                textposition: 'center',
                marker: {
                    color: [this.bubble_background_5],
                    size: [this.bubble_size_4],
                    line: {width: 4, color: this.bubble_border_2}
                }
            },
            {
                x: ['2007'],
                y: [7],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['TRITON<br />TIMI 38<br />2007'],
                mode:'markers+text',
                textposition: 'center',
                marker: {
                    color: [this.bubble_background_5],
                    size: [this.bubble_size_4],
                    line: {width: 4, color: this.bubble_border_2}
                }
            },
            {
                x: ['2009'],
                y: [5],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['PLATO<br />2009'],
                mode:'markers+text',
                textposition: 'center',
                marker: {
                    color: [this.bubble_background_5],
                    size: [this.bubble_size_4],
                    line: {width: 4, color: this.bubble_border_2}
                }
            },
            {
                x: ['2012'],
                y: [6],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['TRILOGY<br />ACS<br />2012'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_5],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_2}
                }
            },
            {
                x: ['2016'],
                y: [7],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['PRAGUE 18<br />2016'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_5],
                    size: [this.bubble_size_1],
                    line: {width: 4, color: this.bubble_border_2}
                }
            },
            {
                x: ['2019'],
                y: [4],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['ISAR REACT 5<br />2019'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_5],
                    size: [this.bubble_size_2],
                    line: {width: 4, color: this.bubble_border_2}
                }
            },

        ]

        return data
    }

    getPlot6Bubbles = () => {
        let data = [
            {
                x: ['1989'],
                y: [6],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['TASS<br />1989'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_6],
                    size: [this.bubble_size_4],
                    line: {width: 4, color: this.bubble_border_3}
                }
            },
            {
                x: ['1996'],
                y: [6],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['CAPRIE<br />1996'],
                mode:'markers+text',
                // textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_6],
                    size: [this.bubble_size_5],
                    line: {width: 4, color: this.bubble_border_3}
                }
            },
            {
                x: ['2001'],
                y: [6],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['STAMI<br />2001'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_6],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_3}
                }
            },
            {
                x: ['2003'],
                y: [6],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['AASPS<br />2003'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_6],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_3}
                }
            },
            {
                x: ['2004'],
                y: [6],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['CADET<br />2004'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_6],
                    size: [this.bubble_size_1],
                    line: {width: 4, color: this.bubble_border_3}
                }
            },
            {
                x: ['2012'],
                y: [6],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['ASCET<br />2012'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_6],
                    size: [this.bubble_size_2],
                    line: {width: 4, color: this.bubble_border_3}
                }
            },
            {
                x: ['2015'],
                y: [7],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['PEGASUS<br />2015'],
                mode:'markers+text',
                // textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_6],
                    size: [this.bubble_size_5],
                    line: {width: 4, color: this.bubble_border_3}
                }
            },
            {
                x: ['2016'],
                y: [5],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['SOCRATES<br />2016'],
                mode:'markers+text',
                // textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_6],
                    size: [this.bubble_size_5],
                    line: {width: 4, color: this.bubble_border_3}
                }
            },
            {
                x: ['2017'],
                y: [7],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['COMPASS<br />2017'],
                mode:'markers+text',
                // textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_6],
                    size: [this.bubble_size_5],
                    line: {width: 4, color: this.bubble_border_3}
                }
            },
            {
                x: ['2018'],
                y: [6],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['DACAB<br />2018'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_6],
                    size: [this.bubble_size_1],
                    line: {width: 4, color: this.bubble_border_3}
                }
            },
            {
                x: ['2019'],
                y: [6],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['TICAB<br />2019'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_6],
                    size: [this.bubble_size_2],
                    line: {width: 4, color: this.bubble_border_3}
                }
            },
        ]
        return data
    }

    getPlot4Bubbles = () => {
        let data = [
            {
                x: ['2015'],
                y: [5],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                hovermode: false,
                type: 'category',
                text: ['ZEUS<br />2015'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_4],
                    size: [this.bubble_size_2],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2015'],
                y: [7],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['LEADERS FREE<br />2015'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_4],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2018'],
                y: [6],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['SENIOR<br />2018'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_4],
                    size: [this.bubble_size_2],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2019'],
                y: [6],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['DEBUT<br />2019'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_4],
                    size: [this.bubble_size_1],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2020'],
                y: [6],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['ONYX ONE<br />2020'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_4],
                    size: [this.bubble_size_2],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2021'],
                y: [7],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['MASTER DAPT<br />2021'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_4],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
        ]

        return data
    }

    getPlot3Bubbles = () => {
        let data = [
            {
                x: ['2013'],
                y: [7],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['WOEST<br />2013'],
                textposition: 'bottom center',
                mode:'markers+text',
                // textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_3],
                    size: [this.bubble_size_1],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2015'],
                y: [5],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['ISAR TRIPLE<br />2015'],
                textposition: 'bottom center',
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_3],
                    size: [this.bubble_size_1],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2016'],
                y: [7],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['PIONEER AF-PCI<br />2016'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_3],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2017'],
                y: [6],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['RE-DUAL<br />2017'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_3],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2019'],
                y: [5],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['AUGUSTUS<br />2019'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_3],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2019'],
                y: [8],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['ENTRUST AF PCI<br />2019'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_3],
                    size: [this.bubble_size_2],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2020'],
                y: [6],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['COBRA<br />REDUCE<br />2020'],
                mode:'markers+text',
                textposition: 'bottom center',
                font: {size: 10},
                marker: {
                    color: [this.bubble_background_3],
                    size: [this.bubble_size_1],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
        ]
        return data
    }

    getPlot2Bubbles = () => {
        let data = [
            {
                x: ['2018'],
                y: [6],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['GLOBAL LEADERS<br />2018'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_2],
                    size: [this.bubble_size_5],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2019'],
                y: [3],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['SMART CHOICE<br />2019'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_2],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2019'],
                y: [6],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['STOP DAPT 2<br />2019'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_2],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2019'],
                y: [8],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['TWILIGHT<br />2019'],
                mode:'markers+text',
                // textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_2],
                    size: [this.bubble_size_4],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2020'],
                y: [5],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['TICO<br />2020'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_2],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_2}
                }
            },
            {
                x: ['2021'],
                y: [5],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['STOP DAPT 2 ACS<br />2021'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_2],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_2}
                }
            },
        ]

        return data
    }

    getPlot1Bubbles = () => {
        let data = [
            {
                x: ['2002'],
                y: [4],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['CREDO<br />2002',],
                mode:'markers+text',
                // textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_4],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2010'],
                y: [8],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['REAL ZEST<br />LATE<br />2010'],
                mode:'markers+text',
                // textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_4],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2012'],
                y: [7],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['EXCELLENT<br />2012'],
                textposition: 'bottom center',
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_2],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2012'],
                y: [5],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['SECURITY<br />2012'],
                textposition: 'bottom center',
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_2],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2012'],
                y: [9],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['PRODIGY<br />2012'],
                textposition: 'bottom center',
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2012'],
                y: [2],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['RESET<br />2012'],
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2013'],
                y: [3],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['OPTIMIZE<br />2013'],
                textposition: 'bottom center',
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2014'],
                y: [2],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['DAPT<br />2014'],
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2014'],
                y: [5],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['DES LATE<br />2014'],
                textposition: 'bottom center',
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2014'],
                y: [8],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['ARCTIC<br />INTERRUPTION<br />2014'],
                textposition: 'bottom center',
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_2],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2015'],
                y: [3],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['ITALIC<br />2015'],
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2015'],
                y: [7],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['ISAR SAFE<br />2015'],
                textposition: 'bottom center',
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2016'],
                y: [3],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['I-LOVE-IT 2<br />2016'],
                textposition: 'bottom center',
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_2],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2016'],
                y: [6],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['OPTIDUAL<br />2016'],
                textposition: 'bottom center',
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_2],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2016'],
                y: [9],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['IVUS-XPL<br />2016'],
                textposition: 'bottom center',
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_2],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2017'],
                y: [3],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['NIPPON<br />2017'],
                textposition: 'bottom center',
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2018'],
                y: [2],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['DAPT-STEMI 2018'],
                textposition: 'bottom center',
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_1],
                    line: {width: 4, color: this.bubble_border_2}
                }
            },
            {
                x: ['2018'],
                y: [5],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['SMART DATE<br />2018'],
                textposition: 'bottom center',
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_2}
                }
            },
            {
                x: ['2018'],
                y: [8],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['OPTIMA-C<br />2018'],
                textposition: 'bottom center',
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_2],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2019'],
                y: [3],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['REDUCE 2019'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_2],
                    line: {width: 4, color: this.bubble_border_2}
                }
            },
            {
                x: ['2019'],
                y: [7],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['IDEAL LM<br />2019'],
                mode:'markers+text',
                textposition: 'bottom center',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_1],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },
            {
                x: ['2020'],
                y: [5],
                // hovertemplate: '%{text}<extra></extra>',
                hovertemplate: '<extra></extra>',
                type: 'category',
                text: ['ONE MONTH DAPT<br />2020'],
                textposition: 'bottom center',
                mode:'markers+text',
                marker: {
                    color: [this.bubble_background_1],
                    size: [this.bubble_size_3],
                    line: {width: 4, color: this.bubble_border_1}
                }
            },

        ]

        return data
    }

}




export default TrialPage;