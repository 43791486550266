import Title from '../page_parts/Title';
import Breadcrumb from '../page_parts/Breadcrumb';
import Axios from "axios";
import * as constants from '../../constants';
import {getValidSlug} from '../../utils/general';
import React from "react";
import TeamMemberItem from '../page_parts/TeamMemberItem';

class TeamMemberSinglePage extends React.Component{

    constructor(props) {
        super(props)

        this.state = {
            allPages: this.props.allPages,
            page: null
        }
    }

    render() {

        if (!this.state.page) {
            console.log('no page content yet');
            return null;
        }
        let page = this.state.page;

        const teamMember = JSON.parse(JSON.stringify(page));
        
        //we expect each page to have a title for breadcrumbs, and title
        page.title = page.name;
        page.description = page.biography;

        return (
            <>
                <Breadcrumb allPages={this.state.allPages} extra={page} />
                <Title currentPage={page} />
                <main id="main-content" className="inner-main spotlight-landing-layout template-no-gaps">
                    <section id="spotlight-area" className="container">
                        <TeamMemberItem teamMember={teamMember} type="full" />
                    </section>
                </main>
            </>

        )

    }

    componentDidMount() {
        this.__loadPageContent();
    }
    

    __loadPageContent = () => {

        const currentSlug = getValidSlug(window.location.pathname);

        Axios.get(constants.TEAM_MEMBERS_API_URL + '?slug=' + currentSlug)
            .then(
                (result) => {
                    let pageData = result.data;

                    if (!pageData || pageData.length === 0) {
                        //no page found: redirect
                        return this.setState({
                            redirect: true
                        })
                    }

                    //if data is an array work with that. 
                    if (Array.isArray(result.data) && result.data.length > 0) {
                        pageData = result.data[0];
                    }

                    document.title = pageData.name + ' - Tara Project';

                    this.setState({
                        page: pageData
                    });
                }
            )
            .catch((error) => {
                // handle error
                console.log(error);
            }
        );
    }
}

export default TeamMemberSinglePage;