import * as constants from '../constants/general';

/**
 * Returns the current page from the menu items. 
 * @param {*} menuItems 
 * @param {*} pathname 
 * @returns {number} the page id or null
 */
export const getCurrentMenuItem = (menuItems, pathname = constants.CURRENT_PATHNAME) =>  {
    let currentSlug = pathname.trim();

    if (currentSlug !== '/'){
        let pathParts = pathname.split('/');
        currentSlug = pathParts[pathParts.length - 1];
    }
   
    
    let leveledMenuItems = levelNestedPages(menuItems);
    let pagesData = getJSONData(leveledMenuItems, ['id','title', 'slug', 'type']);

    let currentPage = pagesData.map((value, index) => {
            return (value.slug.trim() === currentSlug)? value: null
        }).filter(Boolean);

    return (currentPage.length > 0)? currentPage[0]: false;
}

export const getCurrentPage = (pagesList, menuItems, pathname = constants.CURRENT_PATHNAME) => {

    //add links to the pages List so it can be returned as valid page or link
    for (let menuItem of menuItems) {
        if (menuItem.type === 'link') {
            pagesList.push(menuItem);
        }
    }

    let currentSlug = pathname.trim();

    if (currentSlug !== '/'){
        let pathParts = pathname.split('/');
        currentSlug = pathParts[pathParts.length -1];
    }

    let pagesData = getJSONData(pagesList, ['id','title', 'slug', 'type']);

    let currentPage = pagesData.map((value, index) => {
            return (value.slug.trim() === currentSlug)? value: null
        }).filter(Boolean);

    return (currentPage.length > 0)? currentPage[0]: false;

}

/**
 * returns only the selected values from array 
 * @param {Array} items the json array items
 * @param {Array} values the array which to select values from
 */
function getJSONData(items, values) {

    let data = [];

    for (const item of items){
        //skip the links that are not pages. No content to be returned from DB
        // if (item.type !== 'page') {
        //     continue;
        // }

        let filteredItem = {};
        for (const requiredValue of values) {
            if (item.hasOwnProperty(requiredValue)){
                Object.defineProperty(filteredItem, requiredValue, {
                    value: item[requiredValue],
                    writable: true
                });
            }
        }

        data.push(filteredItem);
    }

    return data;
}


/**
 * Recursive functions to level multi nested pages. 
 * @param {Array} items menu items
 * @param {Array} leveledItems 
 */
export const levelNestedPages = (items, leveledItems = []) => {
    for (const item of items){
        leveledItems.push(item);
        if (item.hasOwnProperty(constants.SUB_PAGES_KEY) && item[constants.SUB_PAGES_KEY].length > 0) {
            levelNestedPages(item[constants.SUB_PAGES_KEY], leveledItems);
        }
    }
    return leveledItems;
}

export const addEndingSlashToSlug = (slug) => {
    slug = slug.replace('//', '/');
    if (slug && slug.trim().substr(-1) !== '/'){
        slug += '/';
    }
    return slug;
}

export const getValidSlug = (pathname) => {

    if (pathname.trim() === '/' || pathname.trim() === '//') {
        return '/';
    }

    let slug = false;

    if (pathname.trim().length === 0) {
        return slug;
    }

    let pathParts = pathname.split('/').filter(Boolean);

    if (pathParts.length > 0) {
        slug = pathParts[pathParts.length-1];
    }

    return slug;
}


/**
 * returns from an array of pages, the one that matches the slug
 * @param {string} slug 
 * @param {array} allPages 
 */
export const getPageBySlug = (slug, allPages) => {
    allPages = JSON.parse(JSON.stringify(allPages));
    slug = getValidSlug(slug);

    let currentPage = null;
    for (const page of allPages) {
        if (page.slug === slug) {
            currentPage = page;
            currentPage.slug = addEndingSlashToSlug(currentPage.slug);
        }
    }
    
    return currentPage;
}

/**
 * returns from an array of pages, the one that matches the slug
 * @param {string} slug 
 * @param {array} allPages 
 */
 export const getPageByID = (id, allPages) => {
    allPages = JSON.parse(JSON.stringify(allPages));

    let currentPage = null;
    for (const page of allPages) {
        if (page.id === id) {
            currentPage = page;
            currentPage.slug = addEndingSlashToSlug(currentPage.slug);
        }
    }

    return currentPage;
}

export const extractExcerptContent = (s, size = 350) => {
    var span = document.createElement('span');
    span.innerHTML = s;
    let content = span.textContent || span.innerText;

    if (content.length > size) {
        content = content.substring(0,size) + "...";
    }

    return content;
};

export function getQueryParameterByName(name, url = window.location.href) {
    name = name.replace(/[[]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getIrishTime() {
    let date = null;
    try {
        //IE11 will throw error here
        date = new Date(new Date().toLocaleString('en-us',{ timeZone: 'Europe/Dublin' })).toISOString();
    } catch(err) {
        date = new Date().toISOString();
    }

    return date;
   
}


/**
 * Used when we are on page 2, and click on breadcrumb or menu item, to reset and go to first page. 
 */
export function clickGoToFirstPage(query = '.dropdown a') {
    let list = document.querySelectorAll(query);
    for (var i = 0; i < list.length; ++i) {
        list[i].addEventListener("click", (event) => {
            const firstPageLink = document.querySelectorAll('.links-wrapper .cvri-first-page');
            if (firstPageLink.length > 0) {
                firstPageLink[0].click();
            }
        });
    }
}

export function getPictureURL(picture, format = 'full') {
    if (!picture || picture === null) {
        return picture;
    }

    if (picture.hasOwnProperty('formats') && picture.formats.hasOwnProperty(format)) {
        return picture.formats[format].url;
    } else {
        //default to the full url
        return picture.url;
    }
    
}




export function removeStopwords(str) {

    let stopwords = ['i','me','my','myself','we','our','ours','ourselves','you','your','yours','yourself','yourselves','he','him','his','himself','she','her','hers','herself','it','its','itself','they','them','their','theirs','themselves','what','which','who','whom','this','that','these','those','am','is','are','was','were','be','been','being','have','has','had','having','do','does','did','doing','a','an','the','and','but','if','or','because','as','until','while','of','at','by','for','with','about','against','between','into','through','during','before','after','above','below','to','from','up','down','in','out','on','off','over','under','again','further','then','once','here','there','when','where','why','how','all','any','both','each','few','more','most','other','some','such','no','nor','not','only','own','same','so','than','too','very','s','t','can','will','just','don','should','now']
    let res = []
    let words = str.split(' ')
    for(let i=0;i<words.length;i++) {
       let word_clean = words[i].split(".").join("")
       if(!stopwords.includes(word_clean)) {
           res.push(word_clean)
       }
    }
    return(res.join(' '))
}

/**
 * Returns a random colour class based on a set of colours
 */
export function getColourClass(colours, index) {

    if (!colours) {
        colours = getColours();
    }

    if (index !== 'undefined') {
        index = parseInt(index);
    }

    let colour =  colours[Math.floor(Math.random() * colours.length)];
    if (typeof colours[index] !== 'undefined') {
        colour = colours[index];
    }
    
    let colour_class = '';
    switch(colour) {
        case constants.COLOUR_PANETONE_184:
            colour_class = 'tara_colour_1';
            break;
        case constants.COLOUR_PANETONE_319:
            colour_class = 'tara_colour_2';
            break;
        case constants.COLOUR_PANETONE_2583:
            colour_class = 'tara_colour_3';
            break;
        case constants.COLOUR_PANETONE_GREY_6:
            colour_class = 'tara_colour_4';
            break;
        default:
            colour_class = 'tara_colour_5';
    }

    return colour_class;
}

export function getColours(random = true) {
    let colours = constants.COLOURS;
    if (random) {
        colours = colours.sort(() => Math.random() - 0.5);
    }
    return colours;
}

/**
 * @description Returns the Index of the required position. Useful when passing in an index larger than the available indexes. It will calculate the corresponding position. 
 * To note indexes start at 0
 * @param {Number} desired_index 
 * @param {Array} colours 
 * @returns {Number} The index
 */
export function getColourIndex(desired_index, colours) {
    if (colours.length - 1 < desired_index) {
        desired_index = (desired_index % colours.length);
    }
    return desired_index;
}

/**
 * 
 * @returns an object with the current get params
 */
export function urlQuerySearchToObject() {
    var pairs = window.location.search.substring(1).split("&"),
      obj = {},
      pair,
      i;
  
    for ( i in pairs ) {
      if ( pairs[i] === "" ) continue;
  
      pair = pairs[i].split("=");
      obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
    }
  
    return obj;
}

/**
 * Removes HTML tags from text
 * @param {*} html 
 * @returns text
 */
export function stripHtml(html)
{
   let tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

export function slugify(str)
{
    str = str.replace(/^\s+|\s+$/g, '');

    // Make the string lowercase
    str = str.toLowerCase();

    // Remove accents, swap ñ for n, etc
    var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
    var to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    // Remove invalid chars
    str = str.replace(/[^a-z0-9 -]/g, '') 
    // Collapse whitespace and replace by -
    .replace(/\s+/g, '-') 
    // Collapse dashes
    .replace(/-+/g, '-'); 

    return str;
}
