import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import TaraProject from "./components/TaraProject";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-N5P252K'
}

TagManager.initialize(tagManagerArgs)


ReactDOM.render(
  <React.StrictMode>
    <TaraProject />
  </React.StrictMode>,
  document.getElementById('cvri_wrapper')
);
