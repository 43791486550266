export const FOOTER_CONTACT_TYPE = 'contact';
export const FOOTER_LOGOS_TYPE = 'logos';
export const FOOTER_LINKS_TYPE = 'links';
export const FOOTER_SOCIALS_TYPE = 'socials';

//the parent path/slug of the news/articles items
export const NEWS_PARENT_SLUG = 'news';
export const VIDEOS_PARENT_SLUG = 'videos';
export const PUBLICATIONS_PARENT_SLUG = 'publications';
export const TRIALS_PARENT_SLUG = 'trials';
export const TEAM_MEMBERS_PARENT_SLUG = 'about/our-team';
export const EDUCATION_PAGE = 'education-and-publication/education';
export const CVRI_FORUM = 'education-and-publication/dublin-cardiovascular-research-forum';

export const CHART_SECTION_1_ID = 40;
export const CHART_SECTION_2_ID = 41
export const CHART_SECTION_3_ID = 42;
export const CHART_SECTION_4_ID = 43;
export const CHART_SECTION_5_ID = 47;
export const CHART_SECTION_6_ID = 44;

//RESEARCH page links
export const RESEARCH_TARA_PROJECT = 'tara-project';
