import React from "react";
import { clickGoToFirstPage } from "../../utils/general";
import MenuItem from "./MenuItem";
import SearchBox from "./SearchBox";


class Navigation extends React.Component {


    render() {

        const menuItems = this.props.menuItems;

        const renderMenuItems = [];
        for (const [index, menuItem] of menuItems.entries()) {
            renderMenuItems.push(<MenuItem key={index} data={menuItem} />);
        }

        return (
            <nav role="navigation" aria-label="Main Menu" className="nav-column search-box-item">
                <ul id="gns_drop_down" role="menubar" aria-label="main menu" className="no-bullet">
                    {renderMenuItems}
                    <li className="phone-link d-block d-sm-none">
                        <a href={"tel:" + this.props.cleanPhoneNo} className="cta-phone">{this.props.phoneNo}</a>
                    </li>
                </ul>
                <SearchBox />
            </nav>
        )
    }

    componentDidMount() {
        //go to first page when we click a menu item
        clickGoToFirstPage();
    }
}

export default Navigation;